import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'
import CtaArticle from '../components/ctaArticle'

const Wills = () => {
  return (
    <>
      <Seo
        pageTitle={'Wills'}
        pageDescription={`What happens with a Will
    You can leave clear instructions about how your estate is to be distributed.
    You can appoint guardians to look after your children who are under the age of 18.
    You can stipulate a sensible age for your beneficiaries to inherit.
    You can make specific gifts to individuals. These can range from items of jewellery to sums of cash and can avoid any family disputes over such items.
    You can decide who would look after your pets.`}
      />
      <Article>
        <h1>Wills</h1>

        <h2 className='fs-3 lead py-3'>
          What happens{' '}
          <strong>
            <ins>without</ins>
          </strong>{' '}
          a Will
        </h2>
        <ul>
          <li>The Intestacy Rules decide on how your estate is distributed.</li>
          <li>Conflict between beneficiaries.</li>
          <li>
            Your children under the age of 18 could be placed in temporary
            foster care.
          </li>
          <li>Delays in distributing assets.</li>
        </ul>

        <h2 className='fs-3 lead py-3'>
          What happens{' '}
          <strong>
            <ins>with</ins>
          </strong>{' '}
          a Will
        </h2>
        <ul>
          <li>
            You can leave clear instructions about how your estate is to be
            distributed.
          </li>
          <li>
            You can appoint guardians to look after your children who are under
            the age of 18.
          </li>
          <li>
            You can stipulate a sensible age for your beneficiaries to inherit.
          </li>
          <li>
            You can make specific gifts to individuals. These can range from
            items of jewellery to sums of cash and can avoid any family disputes
            over such items.
          </li>
          <li>You can decide who would look after your pets.</li>
        </ul>

        <p>
          Should you pass away without making a Will, the government has already
          decided on to how your estate is to be distributed, this is called the
          Intestacy Rules. Unfortunately, this means that your estate may not
          pass to those that you want to.
        </p>
        <p>
          The following individuals would have <strong>no</strong> right to
          inherit where someone has passed away without leaving a valid Will:
        </p>
        <ul>
          <li>
            Unmarried partners including those that are not in a Civil
            Partnership
          </li>
          <li>Relatives by marriage</li>
          <li>Close Friends</li>
          <li>Carers</li>
          <li>Charities</li>
        </ul>

        <h2 className='fs-3 lead py-3'>
          <strong>Guardianship</strong> (Children under the age of 18)
        </h2>
        <p>
          Once both parents pass away and you have children under the age of 18,
          each person that decides to look after your children would have to go
          to Court to apply, it is then up to the Court to decide on to whom
          will be responsible to look after your children. Until the Court
          determines this the Social Services will take your children.
        </p>
        <p>
          If there is no family to look after your children, then they could end
          up on temporary foster care.
        </p>
        <p>
          Why not stipulate your trusted individuals who you wish to look after
          your children within your Will today to avoid any distress.
        </p>

        <h2 className='fs-3 lead py-3'>
          <strong>People to consider</strong>
        </h2>
        <ul>
          <li>Someone who is physically able and good of age</li>
          <li>
            Someone who follows your views and understanding (even including
            religious beliefs) of how you wish the child to be brought up.
          </li>
          <li>Someone who will think of the Childs future.</li>
        </ul>
        <CtaArticle />
      </Article>
    </>
  )
}

export default Wills
